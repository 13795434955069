<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    created () {
      // localStorage.getItem('userMsg') &&
      // this.$store.replaceState(
      //   Object.assign(
      //     this.$store.state,
      //     JSON.parse(localStorage.getItem('userMsg'))
      //   )
      // )

      if (localStorage.getItem("store")) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(localStorage.getItem("store"))
          )
        );
        localStorage.removeItem("store")
      }

      //在页面刷新时将vuex里的信息保存到localStorage里
      window.addEventListener("beforeunload", () => {
        localStorage.setItem("store", JSON.stringify(this.$store.state));
      });
    },
  }
</script>
<style>
  #app {
    font-family:  "PingFang SC","Lantinghei SC","Helvetica Neue","Microsoft Yahei","Hiragino Sans GB","Microsoft Sans Serif","WenQuanYi Micro Hei","sans";;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
input[type='number'] {
-moz-appearance: textfield !important;
}
</style>
