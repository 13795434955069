import Vue from "vue";
import VueRouter from "vue-router";

// const originalPush = VueRouter.prototype.push;
// const originalReplace = VueRouter.prototype.replace;
// //push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch(err => err);
// };
// //replace
// VueRouter.prototype.replace = function push(location, onResolve, onReject) {
//     if (onResolve || onReject)
//         return originalReplace.call(this, location, onResolve, onReject);
//     return originalReplace.call(this, location).catch(err => err);
// };
Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/login/login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register"),
  },
  {
    path: "/wechat_login",
    name: "wechat_login",
    component: () => import("@/views/login/wx"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/components/layout"),
    redirect: "/home",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/index"),
        redirect: "/home",
        children: [
          {
            path: "/home",
            name: "home",
            title: "首页",
            component: () => import("@/views/home/index"),
          },
          {
            path: "/courseCenter",
            name: "courseCenter",
            title: "课程中心",
            component: () => import("@/views/courseCenter/index"),
          },
          {
            path: "/train",
            name: "train",
            title: "培训券专区",
            hideNav: true,
            component: () => import("@/views/train/index"),
          },
          {
            path: "/policyAdvice",
            name: "policyAdvice",
            title: "政策资讯",
            component: () => import("@/views/policyAdvice/index"),
          },
          {
            path: "/policyDetail/:id",
            name: "policyDetail",
            title: "政策详情",
            meta:{
              parentName:'policyAdvice',

            },
            hideNav: true,
            component: () => import("@/views/policyAdvice/PolicyDetail"),
          },
          {
            path: "/helpCenter",
            name: "helpCenter",
            title: "帮助中心",
            hideNav: true,
            component: () => import("@/views/helpCenter/index"),
          },
          {
            path: "/answer",
            name: "Answer",
            title: "帮助中心",
            meta:{
              parentName:'helpCenter',

            },
            hideNav: true,
            component: () => import("@/views/helpCenter/answer"),
          },
          // {
          //   path: "/recruitmentActivities",
          //   name: "recruitmentActivities",
          //   title: "百日千万网络招聘活动免费课程",
          //   component: () => import("@/views/recruitmentActivities/index"),
          // },
          {
            path: "/courseCenter/:id",
            meta:{
              parentName: "courseCenter",
            },
            title: "课程详情",
            hideNav: true,
            component: () => import("@/views/courseCenter/courseDetail"),
          },
          {
            path: '/cart',
            parentName: 'cart',
            title: '购物车',
            hideNav: true,
            component: () => import("@/views/cart/cart")
          },
          {
            path: '/order',
            parentName: 'order',
            title: '提交订单',
            hideNav: true,
            component: () => import("@/views/order/order")
          },
          {
            path: '/pay',
            parentName: 'pay',
            title: '支付',
            hideNav: true,
            component: () => import("@/views/order/pay")
          },
          {
            path: '/zhifubao',
            parentName: 'zhifubao',
            title: '支付宝',
            hideNav: true,
            component: () => import("@/views/order/zhifubao")
          },
          {
            path: '/organ',
            parentName: 'organ',
            title: '培训机构',
            hideNav: true,
            component: () => import("@/views/train/organ")
          },
          {
            path: '/organ-detail',
            parentName: 'organDetail',
            title: '培训机构',
            hideNav: true,
            component: () => import("@/views/train/organ-detail")
          },
          {
            path:'/result',
            parentName:'result',
            title:'结果页',
            hideNav: true,
            component: () => import("@/views/helpCenter/result")
          },

        ],
      },
      {
        name: "index",
        path: "index",
        component: () => import("@/views/my/index"),
        redirect: "/my/my_class",
        children: [
          {
            name: "myClass",
            path: "/my/my_class",
            component: () => import("@/views/my/my_class"),
            meta: {
              name: "lesson",
              url: "my_class",
            },
          },
          {
            name: "myOrder",
            path: "/my/my-order",
            component: () => import("@/views/my/my-order"),
            meta: {
              name: "lesson",
              url: "my-order",
            },
          },
          {
            name: "userInfo",
            path: "/my/userInfo",
            component: () => import("@/views/my/userInfo"),
            meta: {
              name: "account",
              url: "userInfo",
            },
          },
          {
            name: "myCertificates",
            path: "/my/my-certificates",
            component: () => import("@/views/my/my-certificates"),
            meta: {
              name: "lesson",
              url: "my-certificates",
            },
          },
          {
            name: "myNotes",
            path: "/my/my-notes",
            component: () => import("@/views/my/my-notes"),
            meta: {
              name: "lesson",
              url: "my-notes",
            },
          },
          {
            name: "myQuestion",
            path: "/my/my-question",
            component: () => import("@/views/my/my-question"),
            meta: {
              name: "lesson",
              url: "my-question",
            },
          },
          {
            name: "myRegistration",
            path: "/my/my-registration",
            component: () => import("@/views/my/my-registration"),
            meta: {
              name: "lesson",
              url: "my-registration",
            },
          },
          {
            name: "myCollection",
            path: "/my/my-collection",
            component: () => import("@/views/my/my-collection"),
            meta: {
              name: "lesson",
              url: "my-collection",
            },
          },
          {
            name: "myInvoice",
            path: "/my/apply-invoice",
            component: () => import("@/views/my/apply-invoice"),
            meta: {
              name: "lesson",
              url: "my-order",
            },
          },
          {
            name: "myOrganization",
            path: "/my/my-organization",
            component: () => import("@/views/my/my-organization"),
            meta: {
              name: "account",
              url: "my-organization",
            },
          },
          {
            name: "AccountSettings",
            path: "/my/account-settings",
            component: () => import("@/views/my/account-settings"),
            meta: {
              name: "account",
              url: "account-settings",
            },
          },
          {
            name: "MessageCenter",
            path: "/my/message-center",
            component: () => import("@/views/my/message-center"),
            meta: {
              name: "message",
              url: "message-center",
            },
          },

        ],
      },
      {
        path: "/exam",
        name: "exam",
        title: "考试",
        component: () => import("@/views/exam/index"),
      },
      {
        path: "/score",
        name: "score",
        title: "score",
        component: () => import("@/views/exam/score"),
      },
      {
        path: "/dayscore",
        name: "dayscore",
        title: "dayscore",
        component: () => import("@/views/exam/dayscore"),
      },
      {
        path: "/jieRecord",
        name: "jieRecord",
        title: "jieRecord",
        component: () => import("@/views/exam/jieRecord"),
      },
      {
        path: "/courseCenter/CoursePlay/:id",
        title: "视频",
        hideNav: true,
        meta:{
          parentName:'courseCenter',
        },
        component: () => import("@/views/courseCenter/CoursePlay"),
      },
      {
        path: "/personal",
        parentName: "personal",
        title: "认证",
        hideNav: true,
        component: () => import("@/views/personal/index.vue"),
      },
      {
        path: "*",
        redirect: "/home",
      },

    ],
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({y: 0}),
  routes,
  mode: "history",
});
router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
})
export default router;
