import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import "../my-theme/index.less";
import { post, get, methods,post1 } from "@/assets/js/axios";
import Empty from "@/components/Empty.vue";
import store from "./store/store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies';
import "./assets/css/index.scss";



// 基本配置
// import Vue from 'vue'
// import Router from 'vue-router'
// Vue.use(Router)

// // 解决报错
// const originalPush = Router.prototype.push
// // 针对 push 方法
// Router.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }




Vue.use(VueCookies)
Vue.prototype.$post = post;
Vue.prototype.$post1 = post1;
Vue.prototype.axios = axios;
Vue.prototype.$get = get;
Vue.prototype.$methods = methods;
Vue.config.productionTip = false;
Vue.component("Empty", Empty);
Vue.use(ViewUI);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
