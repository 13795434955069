<template>
     <div :style="{paddingTop:paddingTop+'px'}" class="empty_page">
         <img src="@/assets/empty.png" alt="">
         <div class="empty_text">{{emptyText}}</div>
     </div>
</template>

<script>
  export default {
    props:{
      emptyText:{
        type:String,
        default:'空空如也~'
      },
      paddingTop:{
        type:String,
        default:'50'
      }
    }
  }
</script>

<style lang="scss" scoped>
   .empty_page{
     text-align: center;
     color: #969799;
     padding-bottom: 50px;
     box-sizing: border-box;
     font-size: 14px;
     img{
       width: 160px;
       height: 160px;
     }

   }
</style>
