import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    shopCarList: [],
    submitOrderList: [],
    videoInfo: {},
    UserInfo: {},
    nowPath:'',
    zhifubao:'',
    videoFlage:0,
    cartNum:0
  },
  mutations: {
    updateShopCarList(state, arr) {
      state.shopCarList = arr;
    },
    updateSubmitOrderList(state, arr) {
      state.submitOrderList = arr;
    },
    updateVideoInfo(state, arr) {
      state.videoInfo = arr;
    },
    updateUserInfo(state, arr) {
      state.UserInfo = arr;
    },
    updatenowPath(state, str) {
      if(str == "policyDetail"){
        state.nowPath = 'policyAdvice';
      }else if(str =="Answer"){
        state.nowPath  =  'helpCenter' 
      }else{
        state.nowPath = str;
      }
      console.log(state.nowPath,"state.nowPath")
    },
    updatezhifubao(state,str){
      state.zhifubao=str
    },
    updatevideoFlage(state,num){
      state.videoFlage=num
    },
    updatecartNum(state,num){
      state.cartNum=num
    }
  },
});
