import axios from "axios";

if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = process.env.VUE_APP_URL;
} else {
  axios.defaults.baseURL = process.env.VUE_APP_URL;
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {},flag=false) {
  return new Promise((resolve, reject) => {
    if((localStorage.getItem('token') && localStorage.getItem('token') != 'undefined') || this.$cookies.get('token')){
      data.token = localStorage.getItem('token') || this.$cookies.get('token')
    }else{
      data.token = ''
    }
    axios({
      url: url,
      method: "post",
      data: data,
      headers: {
        login_token: data.token ||  "",
        // login_token: localStorage.getItem('token')  ? localStorage.getItem('token') : "",
        "Content-Type": "application/json",
      },
    }).then((response) => {
        if (response.data.code == 411 || response.data.code == 301) {
          localStorage.setItem('token','')
          localStorage.removeItem('userType');
          localStorage.removeItem('userid');
          localStorage.removeItem('nickName');

          this.$Notice.warning({
            title:'提示',
            desc:'请登录！'
          })
          this.$router.push({
            path:'/login'
          })


        }else if(response.data.code==200){
          resolve(response.data);
        }else if(response.data.code == 412){
          //微信绑定未注册时
          this.$Notice.warning({
            title:'提示',
            desc:response.data.message
          })
          this.$router.push({
            path:'/login'
          })
        }else{
            this.$Notice.error({
              title:'提示',
              desc:response.data.message
            })
        }

      },
      (err) => {
        reject(err);
        var status = err.response.status;
        if(status == '411') {
          this.$Notice.error({
            title: "提示",
            desc: "登录状态失效，请重新登录",
          });
        } else {
          this.$Notice.error({
            title: "提示",
            desc: "网络开小差了，请稍后再试～",
          });
        }
      }
    );
  });
}
export function post1(url, data = {}) {
  return new Promise((resolve, reject) => {
    data.token = this.$cookies.get('token') || localStorage.getItem('token');
    axios({
      url: url,
      method: "post",
      data: data,
      headers: {
        login_token: data.token|| "",
        // login_token: this.$cookies.get('token')  ? this.$cookies.get('token') : "",
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
        console.log(response,"errerr")
        if (response.data.code == 411 || response.data.code == 301) {
          // this.$router.push({
          //   path:'/login'
          // })
        }else if(response.data.code==200){
          resolve(response.data);
        }else{
          // this.$Notice.error({
          //   title:'提示',
          //   desc:response.data.message
          // })
        }

      },
      (err) => {
        reject(err);
        var status = err.response.status;
        if(status == '411') {
          this.$Notice.error({
            title: "提示",
            desc: "登录状态失效，请重新登录",
          });
          this.$router.push({
            path:'/login'
          })
        } else {
          this.$Notice.error({
            title: "提示",
            desc: "网络开小差了，请稍后再试～",
          });
        }
      }
    ).catch((err) => {
      console.log(err,"errerr")
    });
  });
}
export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    data.token = localStorage.getItem("token") || this.$cookies.get('token');
    axios({
      url: url,
      method: "get",
      params: data,
      headers: {
        'login_token ': data.token ||  '',
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
        if (response.data.code == 411 || response.data.code == 301) {
          this.$router.push({
            path:'/login'
          })
        }
        resolve(response.data);
      },
      (err) => {
        var status = err.response.status;
        if(status == '411') {
          this.$Notice.error({
            title: "提示",
            desc: "登录状态失效，请重新登录",
          });
          this.$router.push({
            path:'/login'
          })
        } else {
          this.$Notice.error({
            title: "提示",
            desc: "网络开小差了，请稍后再试～",
          });
        }
        reject(err);
      }
    );
  });
}

export function methods(url, data = {}) {
  return new Promise((resolve, reject) => {
    data.token = localStorage.getItem("token") || this.$cookies.get('token');
    axios({
      url: url,
      method: "post",
      data: data,
      responseType: "blob",
      headers: {
        'login_token': data.token|| '',
        // 'login_token': localStorage.getItem('token') ? localStorage.getItem('token') : '',
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
        if (response.data.code == 411 || response.data.code == 301) {
          this.$router.push({
            path:'/login'
          })
        }
        resolve(response.data);
      },
      (err) => {
        var status = err.response.status;
        if(status == '411') {
          this.$Notice.error({
            title: "提示",
            desc: "登录状态失效，请重新登录",
          });
          this.$router.push({
            path:'/login'
          })
        } else {
          this.$Notice.error({
            title: "提示",
            desc: "网络开小差了，请稍后再试～",
          });
        }
        reject(err);
      }
    );
  });
}
